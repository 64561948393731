html{
    font-size: $root-em;
}

.environment {
    animation: flasher 1s linear infinite;
    color: white;
    font-size: 2rem;
    position: fixed;
}

@keyframes flasher {
    50% {
        color: red;
        background-color: white;
    }
}

.above-fold {
    margin-top: 50px;
    @include inverse-media-query($on-palm){
        grid-column: span 2;
    }
}

.sidebar{
    display: none;
    background-color: lightgrey;
    grid-area: aside;
    padding: 5vh;
    margin-top: $base-line-height * $base-font-size * 2.25;
    @include inverse-media-query($on-palm){
        display: unset;
    }
}

.mid-wrapper{
    grid-row-start: 1;
    grid-column-end: span 2;
    margin-top: $base-line-height * $base-font-size * 2.25;
    max-width: 100%;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    @include inverse-media-query($on-palm){
        margin-top: unset;
        min-width: 0;
        min-height: 0;
        grid-column-end: span 1;
        grid-row-start: 2;
        grid-row-end: span 2;
        overflow-y: scroll;
    }
}

// Base overrides:

.site-header{
    border-top: none;
    border-bottom: none;
    /* max-height: 6rem; */
    z-index: 1;

    // Persistent Header
    position: fixed;
    width: 100%;
    background-color: black;
    @include inverse-media-query($on-palm){
        grid-row-start: 1;
        grid-column-end: span 2;
    }
}

.site-title {
    &,
    &:visited {
        color: $grey-color-light;
    }
}

.site-nav {
    .page-link {
        color: white;
    }

    @include media-query($on-palm){
        background-color: black; // Allow hamburger menu to be read on mobile
    }
}

.site-footer{
    background-color: $grey-color-dark;
    grid-area: footer;
  @include inverse-media-query($on-palm){
    grid-column-end: span 2;
  }
}

.post-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2em;

  @include media-query($on-palm){
      grid-template-columns: 1fr;
  }

  > .article--latest {
      grid-column-end: span 2;
      grid-row-end: span 1;
      @include media-query($on-palm){
          grid-column-end: 2;
      }
  }

  > .article--draft{
      background-color: grey;
  }

  // .article takes the place of li styling,
  // so reset li to initial to override minima.
  > li {
      margin-bottom: initial;
  }

  > .article {
      width: 100%;
      box-shadow: 10px 10px 10px grey;
      &:hover{
          background-color: rgb(225,225,225);
          > a {
              text-decoration: none;
          }
      }
  }

  .article-excerpt{
      text-decoration: none solid black auto;
      font-size: initial;
      color: initial;
  }
}

.post-link {
    height: 100%;
    margin: 2%;
    text-align: center;
}

.feature_image{
    max-height: 30vh;
    min-width: 50%;
    width: auto;
    object-fit: cover;
}

a.link-promote{
    color: red;
    font-weight: bold;
}

.breadcrumb_item{
    display: inline;
}

.breadcrumb_item::after{
    content: "/";
    padding-left: 1rem;
    font-size: 2rem;
}

.category_list {
    list-style: none;
    display: flex;
    justify-content: space-around;
}

.category-nav{
    grid-area: nav;
    grid-column: span 2;
    position: sticky;
    top: 50px;
    background-color: black;
    a {
        color: white;
    }
}
